export const translations = {
	de_DE: [
		{ header: 'Maliom Ltd.' },
		{ subHeader: 'Exzellenz im Online-Publishing' },
		{ aboutUsTitle: 'Über uns' },
		{ cardOneTitle: 'Unsere Werte' },
		{ cardOneDesc: 'Wir streben nach Exzellenz und Effizienz, indem wir unser Wissen und Know-how einbringen' },
		{ cardTwoTitle: 'Online-Experten' },
		{
			cardTwoDesc:
				'Unsere Teams setzen sich aus einer Vielzahl von Experten zusammen, die auf die neuesten Technologien spezialisiert sind',
		},
		{ cardThreeTitle: 'Kohärenz' },
		{ cardThreeDesc: 'Wir sind Teamplayer und schätzen unser Netzwerk, um die beste Erfahrung zu liefern' },
	],
	en_GB: [
		{ header: 'Maliom Ltd.' },
		{ subHeader: 'Excellence in online publishing' },
		{ aboutUsTitle: 'About Us' },
		{ cardOneTitle: 'Our values' },
		{ cardOneDesc: 'We aim for excellence and efficiency, by bringing our knowledge and expertise' },
		{ cardTwoTitle: 'Online experts' },
		{ cardTwoDesc: 'Our teams are composed of a wide range of experts, specialized in the latest technologies' },
		{ cardThreeTitle: 'Coherence' },
		{ cardThreeDesc: 'We are team players, and we value our network, to deliver the best experience' },
	],
	default: [
		{ header: 'Maliom Ltd.' },
		{ subHeader: 'Excellence in online publishing' },
		{ aboutUsTitle: 'About Us' },
		{ cardOneTitle: 'Our values' },
		{ cardOneDesc: 'We aim for excellence and efficiency, by bringing our knowledge and expertise' },
		{ cardTwoTitle: 'Online experts' },
		{ cardTwoDesc: 'Our teams are composed of a wide range of experts, specialized in the latest technologies' },
		{ cardThreeTitle: 'Coherence' },
		{ cardThreeDesc: 'We are team players, and we value our network, to deliver the best experience' },
	],
};
