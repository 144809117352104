import React from 'react';
import styles from './AboutUsCard.css';
import useTranslations from 'hooks/useTranslations';
import { isMobile } from 'react-device-detect';
import earth from 'components/assets/earth.png';
import team from 'components/assets/team.png';
import buildings from 'components/assets/buildings_old.png';
import flower from 'components/assets/flower.png';

const AboutUsCard = ({ alignment, title, desc, img }) => {
	const titleTranslation = useTranslations(title);
	const descTranslation = useTranslations(desc);

	const imgStyle = {};
	switch (img) {
		case 'earth':
			imgStyle.backgroundImage = `url(${earth})`;
			break;
		case 'buildings':
			imgStyle.backgroundImage = `url(${buildings})`;
			break;
		case 'team':
			imgStyle.backgroundImage = `url(${team})`;
			break;
		case 'flower':
			imgStyle.backgroundImage = `url(${flower})`;
			break;
		default:
			break;
	}
	if (isMobile) {
		return (
			<div className={styles.card}>
				<div className={styles.image} style={imgStyle}></div>
				<div className={styles.textWrapper}>
					<div className={styles.title}>{titleTranslation}</div>
					<div className={styles.desc}>{descTranslation}</div>
				</div>
			</div>
		);
	}
	if (alignment === 'left') {
		return (
			<div className={styles.card}>
				<div className={styles.textWrapper}>
					<div className={styles.title}>{titleTranslation}</div>
					<div className={styles.desc}>{descTranslation}</div>
				</div>
				<div className={styles.image} style={imgStyle}></div>
			</div>
		);
	}
	return (
		<div className={styles.card}>
			<div className={styles.image} style={imgStyle}></div>
			<div className={styles.textWrapper}>
				<div className={styles.title}>{titleTranslation}</div>
				<div className={styles.desc}>{descTranslation}</div>
			</div>
		</div>
	);
};

export default AboutUsCard;
