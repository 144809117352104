import React from 'react';
import styles from './Footer.css';

const Footer = () => {
	return (
		<div className={styles.container}>
			<div>Copyright © 2020 Maliom Ltd.</div>
		</div>
	);
};

export default Footer;
