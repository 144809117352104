import { translations } from 'utils/translations';

const useTranslations = (key) => {
	const language = navigator.language;
	if (language === 'de_DE') {
		return translations.de_DE.find((obj) => obj[key])[key];
	}
	if (language === 'en_GB') {
		return translations.en_GB.find((obj) => obj[key])[key];
	}
	return translations.default.find((obj) => obj[key])[key];
};

export default useTranslations;
