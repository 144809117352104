import React from 'react';
import styles from './Header.css';
import useTranslations from '../../hooks/useTranslations';

const Header = () => {
	return (
		<div className={styles.container}>
			<div className={styles.headerWrapper}>
				<div className={styles.header}>{useTranslations('header')}</div>
				<div className={styles.subHeader}>{useTranslations('subHeader')}</div>
			</div>
			<div className={styles.buildings} />
		</div>
	);
};

export default Header;
