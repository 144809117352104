import React from 'react';
import useTranslations from 'hooks/useTranslations';
import Header from 'components/Header';
import AboutUsCard from 'components/AboutUsCard';
import Footer from 'components/Footer';
import styles from './Home.css';

const Home = () => {
	return (
		<div className={styles.container}>
			<Header />
			<div className={styles.aboutUsSection}>
				<div className={styles.aboutUsTitle}>{useTranslations('aboutUsTitle')}</div>
				<AboutUsCard alignment="left" title="cardOneTitle" desc="cardOneDesc" img="flower" />
				<AboutUsCard title="cardTwoTitle" desc="cardTwoDesc" img="earth" />
				<AboutUsCard alignment="left" title="cardThreeTitle" desc="cardThreeDesc" img="team" />
			</div>
			<Footer />
		</div>
	);
};

export default Home;
